import { useState } from 'react';
import { useEffect } from 'react';
import _ from 'underscore';
import TitleBar from '../components/TitleBar';
import firebasei from '../firebase';
import { getMovie } from '../util/tmdb';
import {Link} from 'react-router-dom';
function Movies(){

    let [movies,setMovies] = useState([]);
    let [count,setCount] = useState(0);
    useEffect(async ()=>{
        let coll = await firebasei.firestore().collection("movies").get();
        let res = coll.docs.map(x=>x.data());
        res = _.sortBy(res,'title');
        setMovies(res);
    },[count]);

    async function reIndex(){
        // return;
        let indexedMovies = movies.map(x=>x.id);
        let coll = await firebasei.firestore().collection("templates2").get();
        let albums = coll.docs.map(x=>x.data().album)//.filter(x=>x.includes("@movie"));
        albums = _.chain(albums).uniq().compact().filter(x=> x && x.includes("@movie-")).value();
        console.log(albums);
        let allMovies = albums.map(x=>Number(x.replace("@movie-","")));
        let nonIndexed = _.difference(allMovies,indexedMovies);
        let deletedMovies = _.difference(indexedMovies,allMovies);
        let albumsPromise = nonIndexed.map(x=>getMovie(x));
        let res = await Promise.all(albumsPromise);
        console.log(res);
        let pro = res.map(async movie=>{
            console.log(movie);
            await firebasei.firestore().collection("movies").doc(""+movie.id).set(movie);
        });
        await Promise.all(pro);
        setCount(count+1);
        console.log(res);
    }

    return (<div>
        <TitleBar/>
        <button onClick={reIndex}>ReIndex</button>
        <div>Movies</div>
        <ul>
        {movies.map(movie=><li><Link to={`/albums/@movie-${movie.id}`}>{movie.title}</Link></li>)}
        </ul>
        {/* {JSON.stringify(movies)} */}
    </div>
    );
}

export default Movies;