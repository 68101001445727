import 'styled-components/macro';
import firebasei from 'firebase';
import {useState,useEffect,useReducer} from 'react';
import { v4 as uuidv4 } from 'uuid';
import firebase from '../firebase';
import {VscCloudUpload} from 'react-icons/vsc';
import ProgressBar from 'react-progressbar';

let storage = firebasei.app().storage("gs://images.memedb.app").ref();
let db = firebasei.firestore();

function Uploader({destPrefix="",record={}}) {

  let [ files, setFiles ] = useState([]);
  let [ dims, setDims ] = useState([]);
  let user = firebasei.auth().currentUser;

  let [progress,dispatchProgress] = useReducer((state,action)=>{
    if(action == null){return []}
    let newState = [...state];
    newState[action.i] = action.val;
    return newState;
  },[]);
  let [isUploading, setIsUploading] = useState(false);
  let email = firebase.auth().currentUser.email;
  useEffect(async ()=>{
    let dimensions = files.map(async file=> await getImageSize(URL.createObjectURL(file)));
    dimensions =await Promise.all(dimensions);
    setDims(dimensions);
    console.log("dimen",dimensions);
  },[files]);

 

  useEffect(()=>{
    window.addEventListener('paste', e => {
       setFiles([...files,...e.clipboardData.files]);
      // x = e.clipboardData.files;
    });

    window.addEventListener('dragover', e=>e.preventDefault(),false);

    window.addEventListener('drop', e => {
      e.preventDefault();
      let dt = e.dataTransfer;
      setFiles([...files,...dt.files]);
     // x = e.clipboardData.files;
   },false);
    return ()=>{
      // window.removeEventListener('dragover');
      // window.removeEventListener('drop');
      // window.removeEventListener('paste');
    }
  },[]);



  async function fileChanged(e){
    await setFiles([...files,...e.target.files]);
  }

  async function getImageSize(src){
    return new Promise((resolve)=>{
      let img = new Image();
      img.src= src; 
      img.onload = ()=>{
        let dim =  {width:img.naturalWidth,height:img.naturalHeight};
        resolve(dim);
      };
    });
    
  }

  function removeFile( index ){
    let newFiles = files.filter((x,i)=> i != index);
    setFiles(newFiles);
  }

  async function upload(){
    setIsUploading(true);
    let uploadTasks = files.map( file =>  storage.child(destPrefix+"/"+uuidv4()+file.name).put(file,{
      contentDisposition :"attachment",
      cacheControl:"public, max-age=31536000"
    }));

    uploadTasks.map((utask,i)=>{
      utask.on('state_changed',(snapshot)=>{
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        dispatchProgress({i:i,val:progress});
      })
    });
      
    let uploadedTasks = await Promise.all( uploadTasks );
    let addToDb = uploadedTasks.map((uploadedTask,i) => {
      console.log("DIMM",dims[i]);
      let obj = {email,...record,fullPath:uploadedTask.ref.fullPath,dimension:dims[i]};
      if(user.email == "lesner005@gmail.com"){
        obj.userHistory = [];
        obj.userId = user.uid;
      }

      console.log(obj);

        return db.collection("templates2").add(obj);
    })

      await Promise.all(addToDb);
      setFiles([]);
      setIsUploading(false);
      setDims([]);
      dispatchProgress(null);





  }



  return (
    <div css={`display:flex;flex-direction:column;align-items:center;background:white;box-shadow:4px 4px 4px #888;padding:8px;margin:8px;`}>
        <div css={`display:flex;flex-wrap:wrap;`}>
          {files.map((file,i)=>
          <div>
            {isUploading || <div onClick={()=>{removeFile(i)}}>X</div>}
            <img css={`width:100px;margin:8px;`} src={URL.createObjectURL(file)}/>
            <div><ProgressBar color={progress[i]=="100"?"Chartreuse":"orange"} completed={progress[i]}/></div>
          </div>
          )}
        </div>
        {isUploading || <label css={`cursor:pointer;padding:8px 16px;border-radius:5px;background:var(--light-pink)`}>
            <VscCloudUpload/>
            Add Photos to {destPrefix}
          <input css={`display:none`} multiple onChange={fileChanged} type="file"/>
        </label>}
        {isUploading || files.length!=0 && <div onClick={upload} css={`cursor:pointer;display:inline-block;background:var(--tea-green);padding:8px;margin:8px;`}>Upload</div>}
    </div>
  );
}

export default Uploader;
