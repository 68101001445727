import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import Gateway from './Gateway';
import {  BrowserRouter as Router,
} from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Gateway/>
    </Router>    
  </React.StrictMode>,
  document.getElementById('root')
);

/*
  movies,
  albums, 
  people
*/