import 'styled-components/macro';

import {deleteTemplate,hideTemplate,updateLabels} from '../util/helper';
import ActorsList from './ActorsList'
import React from 'react';
import _ from 'underscore';
import { useCallback, useState } from 'react';

function TemplateDetailer({templateId,collapse,templateData,isSelected,toggleSelect,album}){
    // let templateData = template.data();


    let onUpdateActorList = useCallback(function(labels){
        updateLabels(templateId,labels);
    },[templateId]);


    return (<div style={{opacity:templateData.hidden?0.4:1}} css={`border:4px solid ${isSelected?'blue;':'#eee;'} box-shadow:4px 4px 4px #888;background:white;margin:8px;display:inline-block;`}>
        <div css={`display:flex;`}>
        <img loading="lazy" onClick={()=>{toggleSelect(templateId)}} width="150" height={templateData.dimension && 150/templateData.dimension.width*templateData.dimension.height} css={`width:150;`} src={`https://ik.imagekit.io/memedb/tr:w-220/https://storage.googleapis.com/images.memedb.app/${encodeURIComponent(templateData.fullPath)}`}/>
        {collapse && <span css={`button{padding:8px;margin:8px;}`}>
        <button css={`background:${templateData.hidden?'green;':"#eee"}`} onClick={()=>hideTemplate(templateId,!templateData.hidden)}>{templateData.hidden?"Show":"Hide"}</button>
        <button css={`background:red;color:white;`} onClick={()=>deleteTemplate(templateId,templateData)}>Delete</button>
        </span>}
        </div>
        {/* <div>{templateData.labels}</div> */}
        {collapse && album.credits && <ActorsList selected={templateData.labels && templateData.labels.filter(x=>x.includes("@actor"))} onChange={onUpdateActorList} actors={album.credits.cast}/>} 
    </div>);
}

export default React.memo(TemplateDetailer);
// export default React.memo(TemplateDetailer,(prev,next)=>{
    
//     let result =  _.isEqual(prev.templateData,next.templateData) && prev.isSelected == next.isSelected;
//     // console.log(result,prev,next);
//     return result;
// });