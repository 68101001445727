import { useEffect } from 'react';
import 'styled-components/macro';
import firebasei from '../firebase';
import Uploader from './Uploader';
import {useState} from 'react';
import {Link,useParams} from 'react-router-dom';
import TemplateLister from './TemplateLister';
import TitleBar from './TitleBar';
import { getMovie } from '../util/tmdb';
import {slugit} from '../util/helper';
let storage = firebasei.storage().ref();
let db = firebasei.firestore();

function Home() {
  console.log("Album rendering");
  let {id} = useParams();
  let [ album, setAlbum] = useState({title:"Loading..."});
  let [albumType,setAlbumType] = useState("custom");
  // let [ actors, setActors] = useState([]);
  useEffect(async ()=>{
    let arr = id.split('-');
    if(arr[1]){
      setAlbumType("movie");
      let album = await getMovie(arr[1]);
      setAlbum(album);
    }else{
      setAlbumType("custom");
      let albumRef = await db.collection("albums").doc(arr[0]).get();
      setAlbum(albumRef.data());
    }
  },[id]);

  function getDestPrefix(){
    if(albumType == "movie"){
      return `movies/${slugit(album.title)}`
    }
    else{
      return slugit(album.title)
    }
  }

  return (
    <div>
      <TitleBar loc={[{url:`/albums/${id}`,title:album.title}]}/>
      <div>
        <Uploader destPrefix={getDestPrefix()} record={{album:id}}/>
        <TemplateLister album={album} id={id}/>
      </div>

    </div>

  );
}

export default Home;
