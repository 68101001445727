import 'styled-components/macro';
import {FaChevronRight, FaLongArrowAltUp} from 'react-icons/fa'
import {Link,useHistory} from 'react-router-dom';
import firebasei from '../firebase';
import AlbumLister from './AlbumLister';
function TitleBar({loc =[]}) {

  let history = useHistory();

  function logout(){
    firebasei.auth().signOut();
  }

  function onAlbumSet(id){
            history.push(`/albums/${id}`);

  }

  return (
    <nav css={`background:white;box-shadow:0px 2px 8px #aaa; display:flex; justify-content:space-around;align-items:center;  a{text-decoration:none;color:black;padding:8px;margin:8px;&:hover{text-decoration:underline}}
      @media screen  and  (orientation:portrait){
        flex-direction:column;
      }
    `}>
        <div css={`display:flex;align-items:center;`}>
          <Link to=""><h2>CMS</h2></Link>
          {loc.map(loca => <><FaChevronRight/> <Link to={loca.url}><h2>{loca.title}</h2></Link></>)}
        </div>

        <AlbumLister onAlbumSet={onAlbumSet}/>
        {/* <div css={``} onClick={logout}>LogOut</div> */}
    </nav>
  );
}

export default TitleBar;
