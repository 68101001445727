import firebase from "firebase";
require("firebase/firestore");
require("firebase/functions");

const firebaseConfig = {
    apiKey: "AIzaSyBGdJETcIgCYddbAAnwcInyhwE5oDr54I4",
    authDomain: "memedb-1.firebaseapp.com",
    // databaseURL: "https://rxfire-525a3.firebaseio.com",
    projectId: "memedb-1",
    storageBucket: "gs://images.memedb.app",
    // messagingSenderId: "844180061847",
    // appId: "1:844180061847:web:12bb3f40c4174fb7"
  /* Add your config from the Firebase Console */
};

firebase.initializeApp(firebaseConfig);

export default firebase;