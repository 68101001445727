import AlbumLister from './AlbumLister';
import 'styled-components/macro';
import firebasei from '../firebase';
import React,{useState} from 'react';
import ActorsList from './ActorsList'
import {deleteTemplate,moveTemplate,slugit,hideTemplate, updateLabels} from '../util/helper';
import _ from 'underscore';
function BulkActionBar({templates,album,setSelectedTemps}){
    console.log("Bulk action bar running");
    
    async function onAlbumSet( id, album ){
        let dest = slugit(album.title);
        if(album.id.includes("@movie")){
          dest = "movies/"+dest
        }
        let pro = templates.map(async template=> {
          return moveTemplate(template.id,template.data(),dest,album.id);
        });
        await Promise.all(pro);
        setSelectedTemps([]);
    }

    function deleteAll(){
      templates.forEach(template=>{
        deleteTemplate(template.id,template.data());
      })
      console.log(templates);
    }

    function setVisibilityAll(isHide){
      templates.forEach(template=>{
        hideTemplate(template.id,isHide)
      })
    }

    function onUpdateActorList(labels){
      templates.forEach(template=>{
        updateLabels(template.id,labels);
      })
    }

    let allActors = templates.map(template=> template.data().labels);
    allActors = _.chain(allActors).flatten().uniq().value();
  
    return (<div css={`background:white;box-shadow:4px 4px 4px #000;margin:8px;margin-top:16px; padding:8px;`}>
      <div>
        {templates.map(template=> <img width="40" src={`https://ik.imagekit.io/memedb/tr:w-220/https://storage.googleapis.com/images.memedb.app/${encodeURIComponent(template.data().fullPath)}`}/>)}
      </div>
      <div css={`padding:8px;margin:8px;
        button{
          padding:8px;
          margin:8px;
          cursor:pointer;
          border:0px;
        }
      `}>
        <button css={`background:red;color:white;`} onClick={deleteAll}>Delete</button>
        <button css={`background:green;color:white;`} onClick={()=>setVisibilityAll(false)}>Show All</button>
        <button onClick={()=>setVisibilityAll(true)}>Hide All</button>
        <button css={`background:#3484F0;color:white;`} onClick={()=>setSelectedTemps([])}>UnSelect All</button>

      </div>
      <span css={`margin:8px;`}>
        {album.credits && <ActorsList selected={allActors} onChange={onUpdateActorList} actors={album.credits.cast}/> }

      </span>
       <AlbumLister onAlbumSet={onAlbumSet}/>

    </div>)
  }

  export default BulkActionBar;