import { useEffect } from 'react';
import 'styled-components/macro';
import firebasei from './firebase';
import Uploader from './components/Uploader';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import TitleBar from './components/TitleBar';
let storage = firebasei.storage().ref();
let db = firebasei.firestore();



function Home() {

  let [albums,setAlbums] = useState([]);


  useEffect(async () => {
    let albums = await db.collection("albums").get();
    albums = albums.docs.map(x=>x.data());
    setAlbums(albums);
    // console.log(addedTemplates.docs[0].data().name);
  },[]);

  return (
    <div>
      <TitleBar/>

      <div>
        {/* <MovieLister/> */}
        <div css={`margin:8px;padding:8px;background:white;box-shadow:4px 4px 4px #aaa`}>
          <h2>Albums</h2>
          {albums.map( album => <Link css={`display:inline-block;margin:8px;padding:16px 8px;text-decoration:none;display:inline-flex;flex-direction:column;padding:8px;border:1px solid black;`} to={`/albums/${album.id}`}>
          <img width="128" height="128" src={`https://ik.imagekit.io/memedb/https://storage.googleapis.com/images.memedb.app/app/albums/${album.id}.jpg`}/>
          { album.title }
          </Link>)}
        </div>
        <div>
          /* <Link to={'/movies'}>Movies</Link> */
        </div>
      </div>
      {/* <Uploader/> */}
    </div>

  );
}

export default Home;
