import 'styled-components/macro';
import Select from 'react-select';
import {components} from 'react-select';
import {useState,useEffect,memo} from 'react';
import _ from 'underscore';
const MultiValueLabel = props => {
    return (
        <>
      {/* <Tooltip content={'Customise your multi-value container!'}> */}
          <img style={{objectFit:"cover",height:'50px',borderRadius:'50%'}} width="50" src={`https://image.tmdb.org/t/p/w200${props.data.profile_path}`}/>

        {/* <components.MultiValueContainer {...props} />
        <components.MultiValueRemove {...props}> */}
      {/* </components.MultiValueRemove> */}
      {/* </Tooltip> */}
      </>
    );
  };


const CustomOptionNew = ({ preloadedOptions, children, ...props }) => {
    return (
      <components.Option {...props}>
          <div css={`display:flex;align-items:center;`}>
          {/* {JSON.stringify(props)} */}
             <img style={{objectFit:"cover",height:'50px',borderRadius:'50%'}} width="50" src={`https://image.tmdb.org/t/p/w200${props.data.profile_path}`}/>
             {children}
        </div>
      </components.Option>
    );
  };
  

function ActorsList({onChange,actors,selected = []}){

    // let x = actors.filter(x=>selected.includes("@actor-"+x.id));
    // x = x.map(x=>({...x,label:x.name,value:x.id}));
    // console.log(selected,x);
    let [selectedActors,setSelectedActors] = useState([]);
    useEffect(()=>{
      // console.log("Use effect execution");
      let x = actors.filter(x=>selected.includes("@actor-"+x.id));
      setSelectedActors(x.map(x=>({...x,label:x.name,value:x.id})));
    },[selected]);
    // let [selectedActors,setSelectedActors] = useState(preSelectedActors);

    // function onChange(){
    //   console.log(...arguments);
    // }

    function onSelectChange(labels){
      onChange(labels.map(x =>`@actor-${x.id}`));
    }

    return (<div css={`width:300px;`}>
     <Select
        placeholder = {"Actors"}
        onChange={onSelectChange}
        value={selectedActors}
        options={actors.map(actor=> ({...actor,value:actor.id,label:actor.name}))}
        isMulti={true}
        // defaultOptions={convertToOptions(defaultOption || [])}
         components={{ Option: CustomOptionNew,MultiValueLabel }}
        //   loadOptions={searchFn}
           />
        {/* {actors.map(actor => <span>
            <img width="40" height="40" style={{borderRadius:'50%'}} src={`https://image.tmdb.org/t/p/w200/${actor.profile_path}`}/>
            {actor.name}jumo
            </span>)} */}
    </div>)
}



export default memo(ActorsList,(prev,next)=>{
  return _.isEqual(prev.selected,next.selected)
});