import 'styled-components/macro';
import Home from './Home';
import Album from './components/album';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import firebasei from './firebase';
import { useState } from 'react';
import {FcGoogle} from 'react-icons/fc';
import {VscLoading}  from'react-icons/vsc';
import Movies from './pages/Movie';

function Routing(){
  return (
    <div>
      <Switch>
        <Route path="/movies/">
          <Movies/>
        </Route>
        <Route path="/movies/:id">
          <Home/>
        </Route>
        <Route path="/albums/:id">
          <Album/>
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </div>
 );
}

function Gateway() {
  
  let [loaded,setLoaded] = useState(false);
  let [ user, setUser ] = useState(null);

  async function login(){
    var provider = new firebasei.auth.GoogleAuthProvider();
    let result = await firebasei.auth().signInWithPopup(provider);
    // setUser(result.user);
  }


  firebasei.auth().onAuthStateChanged(user => {
    setLoaded(true);
    if (user) {
      console.log("Detected",user);
      setUser(user);
      // User is signed in.
    } else {
      console.log("NO user detected");
      setUser(null);
      // User is signed out.
    }
  });
  


  return (
    <div>
      {loaded || <div> <VscLoading css={`font-size:3em;animation:1s rotate infinite ease-in-out; 
        @keyframes rotate{
          0% {transform:rotate(0deg)}
          100% {transform:rotate(360deg)}
        }
      `}/> </div>}
      {user && <Routing/>}
      {user && user.email!=null || !loaded ||
        <div css={`margin-top:300px;width:100%;height:100%;display:flex;flex-direction:column;align-items:center;justify-content:center;`}>
          <h2 css={`padding:20px;`}>Welcome to MemeDb CMS</h2>
          <button css={`cursor:pointer;padding:16px 32px;margin:auto;font-size:2em`} onClick={login}>
            <FcGoogle />
            <span> Log In with Google</span>
          </button>
        </div>}
    </div>
  );
}


export default Gateway;
