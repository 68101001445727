import 'styled-components/macro';
import {useState,useEffect,useRef,useCallback} from 'react';
import firebasei from '../firebase';
import TemplateDetailer from "./TemplateDetailer";
import BulkActionBar from './BulkActionBar';


function TemplateLister({id,album}) {
    console.log("template lister rendering");


  let [templates,setTemplates] = useState([]);
  let [collapse,setCollapase] = useState(false);
  let [selectedTemps, setSelectedTemps]  = useState([]);

  useEffect(async () => {
    firebasei.firestore().collection("templates2").where("album","==",id).limit(100).onSnapshot( snapShot => {

      setTemplates(snapShot.docs);
      
    });

  },[id]);

  let toggleSelect = useCallback(function (tid){
    if(selectedTemps.includes(tid)){
      const newSelect = selectedTemps.filter(x=>x!=tid)
      setSelectedTemps(newSelect);
    }
    else{
      setSelectedTemps([...selectedTemps,tid]);
    }
  },[selectedTemps]);

  let selectAll = function(){
    let alltemplateIds = templates.map(x=>x.id);
    setSelectedTemps(alltemplateIds);
  }


  return (
    <div css={``}>
       {selectedTemps.length!=0 && <BulkActionBar setSelectedTemps={setSelectedTemps} album={album} templates={templates.filter(temp=>selectedTemps.includes(temp.id))}/>}
        <button onClick={()=>{setCollapase(!collapse)}}>{collapse?"Collpase":"Expand"}</button>
        <button onClick={selectAll}>Select All</button>
        <div css={`display:flex;flex-wrap:wrap;`}>
        {templates.map(template => <TemplateDetailer collapse={collapse} album={album} key={template.id} isSelected={selectedTemps.includes(template.id)} toggleSelect={toggleSelect}  templateId={template.id} templateData = {template.data()}/>)}
        </div>
    </div>
  );
}

/*
  album : movie, custom
  label: "actors-323233,

*/




export default TemplateLister;
