import slugify from 'slugify';
import firebasei from '../firebase';

export function parseId(id) {
	let arr = id.split('-');
}

export const slugit = str => slugify(str, {
	lower: true,
	strict: true
});


export async function deleteTemplate(templateId, templateData) {
	let storageDelRef = await firebasei.app().storage("gs://images.memedb.app").ref().child(templateData.fullPath).delete();
	let doc = await firebasei.firestore().collection('templates2').doc(templateId).delete();
	console.log(doc);
	// template.delete();
}

export async function hideTemplate(templateId, value = true) {
	return await firebasei.firestore().collection('templates2').doc(templateId).update({
		hidden: value
	});
	// template.delete();
}

export async function updateLabels(templateId, labels = []) {

	return await firebasei.firestore().collection('templates2').doc(templateId).update({
		labels: labels
	});
	// template.delete();

}




export async function moveTemplate(templateId, templateData, newDest, albumId) {
	// let moveFile = firebasei.app().functions("asia-south1").httpsCallable('moveFile');
	// let newPath = `${newDest}/${templateData.fullPath.split('/').pop()}`
	// console.log(templateData.fullPath,newPath);
	//  try{
	// let res = await moveFile({
	//     src: templateData.fullPath,
	//     dest: newPath});

	return await firebasei.firestore().collection('templates2').doc(templateId).update({
		album: albumId
		// fullPath: newPath
	});

	// }
	// catch(e){
	// 	console.log(e,e.message);
	// }
}
