import 'styled-components/macro';
import {FaChevronRight} from 'react-icons/fa'
import {Link,Route,useHistory} from 'react-router-dom';
import { useEffect, useState,useCallback } from 'react';
import {searchMovie} from '../util/tmdb';
import DebouncedInput from 'react-debounce-input';
import firebasei from '../firebase';
import AsyncSelect, { Async } from 'react-select/async'
import {components} from 'react-select'

const CustomOptionNew = ({ preloadedOptions, children, ...props }) => {
    return (
      <components.Option {...props}>
          <div css={`display:flex;align-items:center;`}>
          {/* {JSON.stringify(props)} */}
             <img style={{objectFit:"cover",height:'50px',borderRadius:'50%'}} width="50" src={`https://image.tmdb.org/t/p/w200${props.data.poster_path}`}/>
             {props.data.title}
             {/* {children} */}
        </div>
      </components.Option>
    );
  };
  
function AlbumLister({onAlbumSet}) {

    
    // let [query,setQuery] = useState("");
    // let [movies,setMovies] = useState([]);
    let [albums,setAlbums] = useState([]);
    // let history = useHistory();

    // useEffect(async ()=>{
    //     let res = await searchMovie(query);
    //     setMovies(res);
    // },[query]);


    useEffect(async () => {
        let albums = await firebasei.firestore().collection("albums").get();
        albums = albums.docs.map(x=>({...x.data(),value:x.data().id,label:x.data().title}));
        setAlbums(albums);
        // console.log(addedTemplates.docs[0].data().name);
      },[]);

      function onChange(x){
          onAlbumSet(x.id,x);
      }

      async function loadOptionss(query){
        let res = await searchMovie(query);
        // return res;
        return res.map(x=>({...x,id:`@movie-${x.id}`}))
        return res;
      }

  return (

        <div css={`position:relative;width:250px;
            @media only screen and (orientation:portrait){
                padding-bottom:8px;
            }
        `} >
        <AsyncSelect 
            placeholder={"search album,movie"}
            loadOptions={loadOptionss}
            components={{ Option: CustomOptionNew }}
            onChange={onChange}

            defaultOptions={albums}/>

        {/* <DebouncedInput type="search" placeholder={"Search Movie,Albums"} debounceTimeout={300} value={query} onChange={({target:{value}})=>{setQuery(value)}} css={`box-shadow:none;padding:8px 16px;margin:8px;outline:none;&:hover{outline:none;}`}/> */}
        {/* {JSON.stringify(albums)} */}
        {/* {<div css={`transition:1s;overflow:hidden;position:absolute;background:#eee;left:8px;top:100%;right:0px;border-top:0px;margin-top:-8px;`} >
        
            {movies.map(movie=><div key={movie.id} css={`position:relative;display:flex;padding:10px;&:hover{background:var(--lemon-yellow-crayola)}`} onClick={()=>{setQuery("");onAlbumSet("@movie-"+movie.id,movie)}}>
                <img css={`width:40px;}`} src={`https://image.tmdb.org/t/p/w200/${movie.poster_path}`}/>
                <div>{movie.title}</div>
            </div>)}
        </div>} */}
                            </div>

       
  );
}

export default AlbumLister;
