// const fetch = require('node-fetch');

const API_KEY = `d23b0f27a63fc829672b2441d2ed2286`;


export async function searchMovie( query ){
    if(!query) return [];
    let yearMatch = query.match(/y:([0-9]+)/);
    let year = "";
    if(yearMatch){
        year = "&year="+yearMatch[1];
        query = query.replace(yearMatch[0],'');
    }
    let url =  `https://api.themoviedb.org/3/search/movie?api_key=${API_KEY}&query=${query}&page=1`+year;
    return await fetch(url,{cache:"force-cache"}).then(res=>res.json()).then(x=>x.results);
}


export async function getMovie( id ){
    let url =  `https://api.themoviedb.org/3/movie/${id}?api_key=${API_KEY}&language=en-US&append_to_response=credits`;
    return await fetch(url).then(res=>res.json());
}


// (async function(){

//     console.log(await searchMovie('enthiran'));

// })();

